<template>
<div class="auth flex flex-col md:flex-row items-center p-4">
    <div class="flex-1 flex justify-end hidden md:block">
      <img alt="Música Protegida" src="@/assets/logo.svg" class="ml-4 logo" style="max-width: 420px">
    </div>
    
    <div class="right_part">
      <h1 class="color_white mb-3" style="font-size: 40px;">{{ $t('forgotPassword.esqueceu-senha' )}}</h1>
      <div class="box edit_box w-full">
        <div>
            <input type="text" @change="response = false" :placeholder="$t('forgotPassword.email')" class="form-control mb-3" v-model="form.email">
            <button 
            @click="submit" 
            class="btn btn-grad half primary"
            v-html="!loading ? $t('forgotPassword.resetar-senha') : $t('forgotPassword.aguarde')"
            :disabled="loading">
            </button>
            <router-link to="/login" class="btn ml-2 half secondary">{{ $t('forgotPassword.voltar') }}</router-link>
        </div>
        <div v-if="response.text" class="pt-4 flex flex-col w-full justify-center text-center">
            <p :class="response.color">{{response.text}}</p>
        </div>  
      </div>
    </div>
</div>
</template>

<script>
import api from '@/api'

export default {
    name : 'ForgotPassword',
    data () {
        return {
            response: { text: '', color: '' },
            loading: false,
            form : {
                email: '',
            }
        }
    },
    methods: {
        async submit() {
            this.loading = true
            let response = await api.post('user/forgot-password', { email: this.form.email })
            this.loading = false
            if(response.errors) {
                Object.getOwnPropertyNames(response.errors).map(error => {
                    response.errors[error].map(message => this.response = {text: message, color: 'text-red' } )
                })
            } else {
                this.form.email = ''
                this.response = { text: response.message, color: 'text-green' }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>